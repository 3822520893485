document.addEventListener('DOMContentLoaded', function () {
	if (document.body.classList.contains('home')) {
		return
	}

	const toggleButton = document.getElementById('menu-toggle')
	const menu = document.getElementById('menu')

	if (toggleButton && menu) {
		toggleButton.addEventListener('click', function () {
			menu.classList.toggle('active')
			if (menu.classList.contains('active')) {
				toggleButton.textContent = 'Close'
			} else {
				toggleButton.textContent = 'Menu'
			}
		})
	} else {
		console.error('Toggle button or menu not found in the DOM.')
	}
})
